var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{key:_vm.actualZq,staticClass:"page-wrapper"},[_c('div',{staticClass:"content-body",class:{ nomargin: _vm.uploadMethod == 1, noPadding: _vm.haveUploads }},[_c('div',{staticClass:"uploadMethodText"},[(_vm.dataPeriod == 0)?_c('div',{staticClass:"uploadMethodItem",class:{ zqLocation: _vm.dataPeriod == 0 }},[_vm._v(" "+_vm._s(_vm.zaNameValue[0])+" ")]):_vm._e(),(_vm.dataPeriod == 1)?_c('div',[_c('router-link',{attrs:{"to":{
              name: 'AdminUploadedTables',
              params: {
                bankId: _vm.bankId,
                version: _vm.version,
                zq: _vm.zq,
                version: _vm.version,
                dataPeriod: 0,
                nsrsbm: _vm.nsrsbm,
                uuid: _vm.uuid,
              },
            }}},[_c('div',{staticClass:"uploadMethodItem"},[_vm._v(_vm._s(_vm.zaNameValue[0]))])])],1):_vm._e(),(_vm.dataPeriod == 1)?_c('div',{staticClass:"uploadMethodItem",class:{ zqLocation: _vm.dataPeriod == 1 }},[_vm._v(" "+_vm._s(_vm.zaNameValue[1])+" ")]):_vm._e(),(_vm.dataPeriod == 0)?_c('div',[_c('router-link',{attrs:{"to":{
              name: 'AdminUploadedTables',
              params: {
                bankId: _vm.bankId,
                version: _vm.version,
                zq: _vm.zq,
                version: _vm.version,
                dataPeriod: 1,
                nsrsbm: _vm.nsrsbm,
                uuid: _vm.uuid,
              },
            }}},[_c('div',{staticClass:"uploadMethodItem"},[_vm._v(_vm._s(_vm.zaNameValue[1]))])])],1):_vm._e()]),_c('table-list',{staticClass:"table-list fix-flexsR",class:{ 'table-lists': _vm.haveUploads },attrs:{"tables":_vm.tables,"manner":"readonly","backTableIndex":_vm.backTableIndex},on:{"listChanged":_vm.listChanged,"showDetailBalance":_vm.showDetailBalance}})],1)]),(_vm.showTables)?_c('div',{staticClass:"containertables"},[_c('div',{staticClass:"tables"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.balanceName))]),_c('div',{staticClass:"closes",on:{"click":_vm.deleteTable}},[_vm._v("×")]),_c('div',{staticClass:"content"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"height":"620","row-key":"subBsDm","tree-props":{ children: 'children', hasChildren: 'hasChildren' }}},[_c('el-table-column',{attrs:{"prop":"subBsDm","label":"科目编码"}}),_c('el-table-column',{attrs:{"prop":"subBsMc","label":"科目名称"}}),_c('el-table-column',{attrs:{"label":"期初余额","align":"center"}},[_c('el-table-column',{attrs:{"prop":"beforeBorrow","label":"借方","align":"right"}}),_c('el-table-column',{attrs:{"prop":"beforeLoan","label":"贷方","align":"right"}})],1),_c('el-table-column',{attrs:{"label":"本期发生额","align":"center"}},[_c('el-table-column',{attrs:{"prop":"currentBorrow","label":"借方","align":"right"}}),_c('el-table-column',{attrs:{"prop":"currentLoan","label":"贷方","align":"right"}})],1),_c('el-table-column',{attrs:{"label":"期末余额","align":"center"}},[_c('el-table-column',{attrs:{"prop":"afterBorrow","label":"借方","align":"right"}}),_c('el-table-column',{attrs:{"prop":"afterLoan","label":"贷方","align":"right"}})],1)],1)],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }