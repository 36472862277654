<template>
  <div>
    <div class="page-wrapper" :key="actualZq">
      <div
        class="content-body"
        :class="{ nomargin: uploadMethod == 1, noPadding: haveUploads }"
      >
        <div class="uploadMethodText">
          <div
            class="uploadMethodItem"
            v-if="dataPeriod == 0"
            :class="{ zqLocation: dataPeriod == 0 }"
          >
            <!-- {{ zq.substring(0, 4) }}年报表 -->
            {{ zaNameValue[0] }}
          </div>
          <div v-if="dataPeriod == 1">
            <router-link
              :to="{
                name: 'AdminUploadedTables',
                params: {
                  bankId,
                  version,
                  zq,
                  version,
                  dataPeriod: 0,
                  nsrsbm,
                  uuid,
                },
              }"
            >
              <div class="uploadMethodItem">{{ zaNameValue[0] }}</div>
            </router-link>
          </div>
          <div
            class="uploadMethodItem"
            v-if="dataPeriod == 1"
            :class="{ zqLocation: dataPeriod == 1 }"
          >
            {{ zaNameValue[1] }}
          </div>
          <div v-if="dataPeriod == 0">
            <router-link
              :to="{
                name: 'AdminUploadedTables',
                params: {
                  bankId,
                  version,
                  zq,
                  version,
                  dataPeriod: 1,
                  nsrsbm,
                  uuid,
                },
              }"
            >
              <div class="uploadMethodItem">{{ zaNameValue[1] }}</div>
            </router-link>
          </div>
        </div>
        <table-list
          :tables="tables"
          @listChanged="listChanged"
          manner="readonly"
          class="table-list fix-flexsR"
          :class="{ 'table-lists': haveUploads }"
          @showDetailBalance="showDetailBalance"
          :backTableIndex="backTableIndex"
        ></table-list>
      </div>
    </div>
    <div v-if="showTables" class="containertables">
      <div class="tables">
        <div class="title">{{ balanceName }}</div>
        <div class="closes" @click="deleteTable">×</div>
        <div class="content">
          <el-table
            :data="tableData"
            style="width: 100%"
            height="620"
            row-key="subBsDm"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="subBsDm" label="科目编码"></el-table-column>
            <el-table-column prop="subBsMc" label="科目名称"></el-table-column>
            <el-table-column label="期初余额" align="center">
              <el-table-column
                prop="beforeBorrow"
                label="借方"
                align="right"
              ></el-table-column>
              <el-table-column
                prop="beforeLoan"
                label="贷方"
                align="right"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="本期发生额" align="center">
              <el-table-column
                prop="currentBorrow"
                label="借方"
                align="right"
              ></el-table-column>
              <el-table-column
                prop="currentLoan"
                label="贷方"
                align="right"
              ></el-table-column>
            </el-table-column>
            <el-table-column label="期末余额" align="center">
              <el-table-column
                prop="afterBorrow"
                label="借方"
                align="right"
              ></el-table-column>
              <el-table-column
                prop="afterLoan"
                label="贷方"
                align="right"
              ></el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import {
  getInfoByCompanyNsrsbm,
  useAuthorize,
  checkCompany,
  breezeOuter,
  showBalanceDetail,
  genTestReport,
  addReportValue,
  isFindNeedExcel,
  findFinalSettlementCondition,
  findTaxAutoStatusByUuid,
} from "@/api/api";
import TableList from "@/pages/Test/UploadTable/components/TableList.vue";
import toTip from "@/pages/Test/UploadTable/components/totip";
import MainHeader from "@/pages/components/MainHeader";
import { getUserTables, clearUploadedTableforTest, loadTableData } from "@/api/table";
import { zq2name, previousZq, zq2obj } from "@/utils/helper";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      dialogVisible: false,
      isloaded: false,
      canUpload: false,
      processTexting: true,
      processTexted: false,
      progressUpload: false,
      progressUploadStatus: "0",
      tables: {},
      tipDialogVisible: false, //刚开始进入的提示框
      fullPage: true,
      isLoading: false,
      acknowledgeDialogVisible: false,
      uploadErrorDialogVisible: false, //控制无法识别的表的弹窗
      uploadErrors: [], //错误的表名及原因
      templateDownloadURL: "",
      hasItemsInList: false,
      actualZq: 0,
      zqs: [],
      showSDSDialog: false,
      uploadMethod: sessionStorage.getItem("method") || 1,
      checkParts: false,
      code: 9996,
      index: -1,
      step2Msg: null,
      valueMsg: "",
      area: "",
      bizNo: "",
      radioIndex: "",
      passwordInput: "",
      clickQr: "",
      index1Data: "",
      indexJylsh: "",
      mobile: "",
      mobileCode: "",
      haveUploads: false,
      ban: "余额表",
      zzs: "增值税表",
      sds: "所得税表",
      cw: "财务表",
      showTables: false,
      balanceName: "",
      tableData: [],
      taxSiteUsername: this.$store.state.companyInfo.nsrsbh || "",
      batchNo: "",
      isEnableAutoGetData: true, // 汇算清缴是否已经结束
      isRequiredTableUploaded: false, //必要的表格是否已经上传
      isTaxAutoProcessing: false, //自动取数进行中
      isVersionList: [9, 11, 17],
      baseVersion: 12,
      checkBaseInfoData: "",
      zaNameValue: [],
      backTableIndex: 1  //是否返回要保存上次的index
    };
  },
  components: {
    TableList,

    MainHeader,
  },
  props: {
    dataPeriod: [Number, String],
    zq: [Number, String],
    productName: String,
    bankId: String,
    uuid: String,
    version: [Number, String],
    nsrsbm: [Number, String],
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("UploadTable", ["permissionID", "SDSSelection"]),

    correctDialogVisible: {
      get: function () {
        return this.$store.state.UploadTable.correctDialogVisible;
      },
      set: function (value) {
        this.setCorrectDialogVisible(value);
      },
    },
    zqName: function () {
      return zq2name(this.actualZq);
    },
    progress: function () {
      let ret = 2;
      if (this.dataPeriod == 0) {
        ret = 2;
      } else if (this.dataPeriod == 1) {
        ret = 3;
      }
      return ret;
    },
    SDStables() {
      let ret = [];
      if (this.tables.所得税表) {
        ret = this.tables.所得税表.list;
      }
      return ret;
    },
    tablesList() {
      const ret = [];
      if (!this.tables) {
        return ret;
      }

      for (const tableGrp of Object.values(this.tables)) {
        for (const table of tableGrp.list) {
          ret.push(table);
        }
      }
      return ret;
    },
  },

  async mounted() {
    this.isloaded = false;

    await this.init();
    this.isloaded = true;
    this.showBaseInfo();
    this.zqNameValue();
  },
  methods: {
    zqNameValue() {
      let zaNameValue = [];
      let zqLast = this.zq.substr(this.zq.length - 1, 1);

      if (zqLast == 1) {
        let nextMonth = "";
        let nextYear = "";
        if (this.zq.slice(4, 6) == "01") {
          nextMonth = "12";
          nextYear = Number(this.zq.slice(0, 4)) - 1;
        } else {
          nextMonth = (Number(this.zq.slice(4, 6)) - 1).toString();
          nextYear = this.zq.slice(0, 4);
        }
        if (nextMonth.length == 1) {
          nextMonth = "0" + nextMonth;
        }
        zaNameValue[0] = this.zq.slice(0, 4) + "年" + this.zq.slice(4, 6) + "月";
        zaNameValue[1] = nextYear + "年" + nextMonth + "月";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 4) {
        let nextYear = this.zq.slice(0, 4);
        zaNameValue[0] = this.zq.slice(0, 4) + "年";
        zaNameValue[1] = Number(nextYear) - 1 + "年";
        this.zaNameValue = zaNameValue;
      }

      if (zqLast == 2) {
        let year = this.zq.slice(0, 4);
        let quer = this.zq.slice(5, 6);
        let nextQuer;
        let nextYear;
        if (quer == 1) {
          nextYear = year - 1;
          nextQuer = 4;
        } else {
          nextYear = year;
          nextQuer = quer - 1;
        }

        zaNameValue[0] = year + "年" + quer + "季度";
        zaNameValue[1] = nextYear + "年" + nextQuer + "季度";
        this.zaNameValue = zaNameValue;
      }
    },

    nextBaseInfo() {
      if (this.version == this.baseVersion) {
        let params = {
          nsrsbm: this.$store.state.companyInfo.nsrsbm,
        };
        getInfoByCompanyNsrsbm(params).then((res) => {
          this.checkBaseInfoData = res.data;
          if (!this.checkBaseInfoData || !this.checkBaseInfoData.projectName) {
            this.dialogVisible = true;
            return 1;
          } else {
            return 2;
          }
        });
      } else {
        return 2;
      }
    },

    closeBase() {
      this.dialogVisible = false;
    },

    showBase() {
      this.dialogVisible = true;
    },

    cancelBaseinfo() {
      this.dialogVisible = false;
    },

    showBaseInfo() {
      if (this.version == this.baseVersion) {
        this.dialogVisible = true;
      }
    },

    ...mapMutations("UploadTable", ["setCorrectDialogVisible"]),
    //不能自动取数的提示
    tipsFornoAutoGetData() {
      if (this.uploadMethod == 1 && !this.isTaxAutoProcessing) {
        this.$confirm(
          "2020年汇算清缴未结束，自动取数提取数据有限，建议您使用手动上传",
          "提示",
          {
            confirmButtonText: "切换手动",
            cancelButtonText: "知道了",
            type: "warning",
          }
        ).then(() => {
          this.editUser();
          //this.uploadMethod=2;
        });
      }
    },
    overUpload() {
      // this.openF5Refresh();
      this.progressUploadStatus = 100;
      this.processTexted = true;
      this.processTexting = false;
      setTimeout(() => {
        this.progressUpload = false;
        this.processTexted = false;
        this.processTexting = true;
      }, 1000);
    },

    changeLength(val) {
      // this.stopF5Refresh();
      this.progressUpload = true;
      this.progressUploadStatus = Number(((val.status / val.length) * 100).toFixed(2));
      if (this.progressUploadStatus >= 100) {
        this.progressUploadStatus = 100;
      }
    },
    // 查看余额表
    showDetailBalance(id, zq) {
      let token = JSON.parse(localStorage.getItem("token")).token;
      loadTableData(zq, id, token, this.uuid).then((res) => {
        if (res.msgCode == -1) {
          this.$message({
            message: res.msgInfo,
            type: "warning",
          });
          return;
        }
        this.tableData = res.data.dataJson;
      });
      this.balanceName = zq.slice(0, 4) + "年余额表";
      this.detailZq = zq;
      this.showTables = true;
    },
    // 关闭查看余额表
    deleteTable() {
      this.showTables = false;
    },

    showDetailBox() {
      this.haveUploads = true;
    },

    // 打开授权协议
    openlicense() {
      this.$refs.opendialog.openDialog();
    },
    //根据会计制度获取不同的报表弹窗
    toast() {
      if (this.dataPeriod == 0 && this.uploadMethod == 2) {
        this.showSDSFilterDialog();
      }
    },
    // 补全信息确定
    submit() {
      this.checkParts = false;
      // 补全信息后更新企业信息
      this.deleteCache();
      // 补全之后切换手动上传
      this.uploadMethod = 2;
      sessionStorage.setItem("method", this.uploadMethod);
    },
    async deleteCache() {
      this.$store.cache.delete("loadCompanyRenzheng"); //删除认证缓存
      this.$store.cache.delete("loadCompanyInfo"); //删除企业信息
      await this.$store.cache.dispatch("loadCompanyRenzheng");
      await this.$store.cache.dispatch("loadCompanyInfo");
      await this.loadList();
      await this.toast();
      await this.loadUploadInit();
    },
    // 关闭补全信息
    cancel() {
      this.checkParts = false;
    },
    // 子组件值变化传递到父组件
    changeName(val) {
      this.taxSiteUsername = val.name;
      this.index = val.index;
      this.step2Msg = val.step2Msg;
      this.valueMsg = val.value;
      this.area = val.province;
      this.bizNo = val.bizNo;
      this.radioIndex = val.radio;
      this.passwordInput = val.passwordInput;
      this.indexJylsh = val.indexJylsh;
      this.index1Data = val.index1Data;
      this.mobile = val.mobile;
      this.mobileCode = val.mobileCode;
      this.batchNo = val.batchNo;
    },

    // 判断是否需要补全信息，如果是返回1，走checkAmount
    checkcompany() {
      let params = {
        userToken: JSON.parse(localStorage.getItem("token")).token,
      };
      checkCompany(params).then((res) => {
        this.code = res.msgCode;
        if (res.msgCode == 9996) {
          this.checkParts = true;
        }
        if (res.msgCode == 9997) {
          this.checkParts = true;
        }
        if (res.msgCode == 9998) {
          this.checkParts = true;
        }
        this.checkAmount(res);
      });
    },
    // 如果不需要补全信息，则请求表格接口，然后显示表格信息
    async checkAmount(res) {
      if (res.msgCode == 1) {
        this.uploadMethod = 2;
        sessionStorage.setItem("method", this.uploadMethod);
        if (this.dataPeriod == 0 && this.uploadMethod == 2) {
          await this.loadList();
          await this.showSDSFilterDialog();
        }
      }
    },
    // 切换上传方式
    async editUser() {
      // 如果初始化默认是自动取数，切换的时候则判断是否需要补全信息，补全之后再切换为手动方式
      if (this.uploadMethod == 1) {
        await this.checkcompany();
      } else {
        this.uploadMethod == 1 ? (this.uploadMethod = 2) : (this.uploadMethod = 1);
        sessionStorage.setItem("method", this.uploadMethod);
      }

      //首次进入显示温馨提示
      const hideWenxinTip = localStorage.getItem("hideWenxinTip");
      if (!hideWenxinTip) {
        // this.tipDialogVisible = true;
      }
    },
    handleShowAllSDSTablesBtnClick() {
      this.$store.commit("UploadTable/setSDSSelection", {
        SDSlist: this.tables.所得税表.list,
      });
    },
    handleSDSDialogSubmit() {
      this.showSDSDialog = false;
    },
    //第一阶段显示所得税填报选择
    showSDSFilterDialog() {
      if (this.SDSSelection === null) {
        // this.showSDSDialog = true;
      }
    },
    checkIfAllFiltedTableFilled() {
      //把所有列表展平
      const tables = [];
      for (const key in this.tables) {
        if (this.tables.hasOwnProperty(key)) {
          const tablesInkey = this.tables[key].list;
          for (const table of tablesInkey) {
            tables.push(table);
          }
        }
      }
      return this.SDSSelection.every((aSDSSelection) => {
        const templateId = aSDSSelection.templateId;
        const found = tables.find((table) => {
          return templateId == table.templateId && table.status != "未上传";
        });
        return !!found;
      });
    },
    //本期的下一步按钮
    handleBtnNext() {
      this.$router.push({
        name: "upload-table",
        params: { zq: this.zq, zqLocation: this.zqLocation, dataPeriod: 1 },
      });
    },
    //上期的下一步按钮点击
    handleBtnNext2() {
      if (this.uploadMethod == 2) {
        let params = {
          nsrsbh: this.$store.state.companyInfo.nsrsbm,
          zq: this.zq,
          uuid: this.uuid,
        };
        addReportValue(params).then((res) => {
          if (res.code != 200) {
            this.$message.error(res.message);
            return;
          }
        });
      }

      // tag不等于9或者11的 跳余额表页面
      if (
        this.uploadMethod == 1 &&
        this.isVersionList.indexOf(Number(this.version)) == -1
      ) {
        this.useauthorize(() => {
          localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
          this.$router.push({
            name: "test-Balance",
            params: { userName: this.taxSiteUsername },
          });
        });
        return;
      }
      // 运营版报税版tag9或11自动取数，成功后直接跳成功页面
      if (
        this.uploadMethod == 1 &&
        this.isVersionList.indexOf(Number(this.version)) > -1
      ) {
        this.useauthorize(() => {
          localStorage.setItem("taxSiteUsername", this.taxSiteUsername);
          // 新的check接口
          let params = {
            token: this.$store.state.authorization,
            appId: localStorage.getItem("appid_3rd"),
            nsrsbh: this.$store.state.companyInfo.nsrsbm,
            tag: this.version,
            uuid: this.uuid,
            zq: this.zq,
            invoiceNsrsbh: this.taxSiteUsername,
            invoiceCompanyName: this.$store.state.companyInfo.companyName,
            getDataWay: sessionStorage.getItem("method") == 2 ? 1 : 2,
            nameless:sessionStorage.getItem('isAnonymous')==='true'
          };
          genTestReport(params).then((res) => {
            if (res.msgCode == 1) {
              this.$router.push({
                name: "test-InvoiceUpload",
              });
            } else {
              //如果遇到"该服务暂不可用"就弹出错误信息并返回首页
              this.$alert(res.msgInfo, "错误", {
                confirmButtonText: "确定",
                type: "error",
                callback: (action) => {
                  this.$router.push({ name: "homePage" });
                },
              });
            }
          });
        });
        return;
      }

      //如果选择所得税表表数量不等于所有所得税表表的数量 并且 不是每张选择的所得税都被填了 则提示

      if (
        this.SDSSelection &&
        this.tables.所得税表.list.length != this.SDSSelection.length &&
        !this.checkIfAllFiltedTableFilled()
      ) {
        this.$confirm("检测到您勾选所得税填报，未全部填写完成是否继续填写", "温馨提示", {
          confirmButtonText: "下一步",
          cancelButtonText: "继续填写",
          type: "warning",
        }).then(() => {
          //如果是发票版或者定制版则调招到发票检测页面
          if (this.isVersionList.indexOf(Number(this.version)) == -1) {
            this.$router.push({
              name: "test-invoice",
            });
          } else {
            //否则直接开始上传流程
            this.$refs["testDataUploader"].handleBtnNext2Click();
          }
        });
      } else {
        //如果是发票版或者定制版则调招到发票检测页面 ,9是运营版11是报税版 所以排除
        if (this.isVersionList.indexOf(Number(this.version)) == -1) {
          this.$router.push({
            name: "test-invoice",
          });
        } else {
          //否则直接开始上传流程
          this.$refs["testDataUploader"].handleBtnNext2Click();
        }
      }
    },

    async init() {
      this.isLoading = true;

      if (this.dataPeriod == 1) {
        //上个征期
        this.actualZq = previousZq(this.zq);
        this.zqs.push(this.zq);
        this.zqs.push(this.actualZq);
      } else if (this.dataPeriod == 0) {
        //当前征期
        this.actualZq = this.zq;
      }

      await this.loadList();

      //下载模板的连接
      switch (this.companyInfo.kjzd) {
        case "qykjzd":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%88%B6%E5%BA%A6.zip";
          break;
        case "qykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
        case "xqykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E5%B0%8F%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
      }

      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    },

    loadUploadInit() {
      //下载模板的连接
      switch (this.companyInfo.kjzd) {
        case "qykjzd":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%88%B6%E5%BA%A6.zip";
          break;
        case "qykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
        case "xqykjzz":
          this.templateDownloadURL =
            "https://shuian-file.oss-cn-hangzhou.aliyuncs.com/report_zip/%E5%B0%8F%E4%BC%81%E4%B8%9A%E4%BC%9A%E8%AE%A1%E5%87%86%E5%88%99.zip";
          break;
      }
    },
    clickbtn() {
      this.acknowledgeDialogVisible = false;
    },
    handleTipDialogBtnClick() {
      this.tipDialogVisible = false;
      localStorage.setItem("hideWenxinTip", true);
    },
    listChanged() {
      this.loadList();
    },
    //清空所有已经上传的报表
    handleBtnPurgeDataClicker() {
      this.$confirm(`所有文件将被删除, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const result = await clearUploadedTableforTest(
          this.companyInfo.nsrsbm,
          this.actualZq,
          this.authorization,
          this.uuid
        );

        if (result.code == 200) {
          this.$emit("listChanged");
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.loadList();
        } else {
          this.$message.error(result.message);
        }
      });
    },
    //修正表格的弹出框
    showCorrectDialog() {
      this.correctDialogVisible = true;
    },
    //错误原因弹出框
    showUploadErrorDialog() {
      this.uploadErrorDialogVisible = true;
    },
    submitData() {
      this.correctDialogVisible = true;
    },
    async loadList() {
      //bankId, qykjzd, nsrsbh, zq
      const tables = await getUserTables(
        this.bankId,
        this.version,
        this.nsrsbm,
        this.actualZq,
        this.uuid,
        false
      );

      // 如果有上传的数据就展开表格

      if (tables.data && tables.msgCode != -1) {
        for (let i in tables.data) {
          // console.log(i);
          if (tables.data[i].uploadNum > 0) {
            this.haveUploads = true;
          }
        }
      }

      // 报税版隐藏余额表
      if (this.version == 3 && tables.data) {
        for (let i in tables.data) {
          if (i == "余额表") {
            delete tables.data[i];
          }
        }
      }

      //过滤未上传的表格
      this.tables = {};
      let i = 0;
      for (const grpName in tables.data) {
        i++;
        const grpData = tables.data[grpName];
        const newGrp = [];

        for (const table of grpData.tableInfo) {
          //if (table.uploadStatus != "未上传") {
          const {
            tableNameCn,
            tableNameEn: templateId,
            uploadStatus: status,
            tableNum,
            zq,
            children,
          } = table;

          //整理一下children的格式
          const thechildren = children.map((child) => {
            i++;
            const {
              tableNameCn,
              tableNameEn: templateId,
              uploadStatus: status,
              tableNum,
              zq,
              children,
            } = child;
            return {
              name: `${tableNameCn}`,
              status,
              zq,
              templateId,
              tableNum,
              children,
              id: i,
            };
          });
          i++;

          const item = {
            name: `${tableNameCn}`,
            status,
            zq,
            templateId,
            tableNum,
            id: i,
            children: thechildren,
          };

          newGrp.push(item);
          //}
        }

        if (newGrp.length > 0) {
          this.tables[grpName] = {
            list: newGrp,
            totalNum: grpData.totalNum,
            uploadNum: grpData.uploadNum,
          };
        }
      }

      if (Object.keys(this.tables).length > 0) {
        this.hasItemsInList = true;
      } else {
        this.hasItemsInList = false;
      }

      // 是否已经上传必要表格
      isFindNeedExcel(this.uuid, this.zq).then((res) => {
        this.isRequiredTableUploaded = res.data;
      });
    },
    async onUpload(file, data) {
      //如果有错误的表格则弹出弹出框
      if (data.data.errorData.length > 0) {
        this.uploadErrors = data.data.errorData;
        this.showUploadErrorDialog();
      }

      const groups = data.data.succData;

      //根据上传的excel构建本地对应的数组

      //上传文件后需要纠错的表格信息
      const tables4correction = [];

      for (const groupName in groups) {
        const tables = groups[groupName]; //分组里包含的表

        for (const table of tables) {
          const status = table.uploadStatus;
          const { templateId, sysFileName, userFileName } = table;

          if (status === "待修改") {
            table.data = {};
            table.data.templateId = templateId;
            table.data.groupName = groupName;
            table.data.sysFileName = sysFileName;
            table.data.userFileName = userFileName;

            tables4correction.push(table.data);
          }
        }
      }
      if (tables4correction.length > 0) {
        this.$store.commit("UploadTable/setCorrectionTableData", {
          tables4correctionData: tables4correction,
        });
        this.showCorrectDialog();
      }
      this.loadList();
    },
    handleCorrectDialogClose() {
      this.loadList();
    },
  },
  watch: {
    $route: function (to, from) {
      window.scrollTo(0, 0);
      this.init();
      // 拿到目标参数 to.query.id 去再次请求数据接口
      //this.loadPageData(to.query.id);
      //this.dataPeriod;
      //debugger;
    },
    // uploadMethod:{
    //   handler:function(val){
    //     this.tipsFornoAutoGetData()
    //   }
    // }
  },
};
</script>

<style lang="stylus" scoped>
.data-loading-wrapper {
  height: 722px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .text {
    margin-top: 47px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 25px;
  }
}

.modify-table-wrapper {
  margin-top: 30px;
}

.correctDialog {
  padding: 0;

  >>> .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }
}

.tipDialog {
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;

    .img {
      width: 174px;
      flex-shrink: 0;
    }

    .right-wrapper {
      margin-left: 50px;

      .title {
        font-weight: bold;
        font-size: 18px;
        color: #062340;
      }

      .desc {
        margin-top: 16px;
        color: #6b7897;
        font-size: 16px;
        line-height: 1.5;
      }

      .btn-i-get {
        height: 40px;
        line-height: 40px;
        margin-top: 17px;
      }
    }
  }
}

.the-title {
  margin: 30px 30px 30px 0;
}

.content-body {
  margin: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(17, 58, 99, 0.05);
  border-radius: 8px;
  padding: 20px;
  margin-top: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  padding-top: 0;
}

.upload-footer {
  margin-top: 16px;

  .tip {
    float: left;
    color: #FAAD14;
    font-size: 14px;
  }

  .acknowlege, .btn-download {
    background: #fff;
    font-size: 14px;
    float: right;
    color: #2F54EB;
    vertical-align: middle;

    .icon {
      vertical-align: middle;
      font-size: 22px;
    }
  }

  .acknowlege {
    margin-right: 48px;
    cursor: pointer;
    float: right;
  }

  .acknowlege:hover, .btn-download:hover {
  }
}

.table-list {
  margin-top: 24px;
}

.footer {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;

  .btn {
    margin: 0 10px;
  }

  .btn-purge-data {
    color: #ff6464;
  }
}

// >>> .el-dialog__header {
// padding: 0;
// }

// >>> .section-title {
// margin-top: 10px;
// }
.page-wrapper {
  padding-bottom: 84px;
}

.progressdis {
  width: 90%;
  margin: 2px auto 16px;
}

.btn-right-none {
  margin-right: 6px !important;
}

// .report-method {
// margin: 20px;
// }
.nomargin {
  margin: 0 30px;
  padding: 0;
}

.title-content {
  // width: 1260px;
  height: 96px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 0px 0px rgba(238, 238, 238, 1);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.mainHeader {
  display: flex;
  justify-content: space-between;
}

.text-status {
  width: 10%;
}

.uploadMethodText {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 375px;
}

.uploadMethodItem {
  width: 174px;
  height: 49px;
  line-height: 49px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 -4px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 18px 18px 0 0;
  cursor: pointer;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.zqLocation {
  color: #2F54EB;
}

.fix-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(217, 217, 217, 1);
  margin-bottom: 24px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.fix-flexs {
  display: flex;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(47, 84, 235, 1);
}

.fix-flexs1 {
  margin-left: 24px;
}

.byHand {
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(47, 84, 235, 1);
  line-height: 22px;
  margin-bottom: 20px;
  cursor: pointer;
  margin-top: 24px;
}

.drog_texts {
  padding-top: 24px;
  padding-bottom: 8px;
  width: 304px;
}

.upload-text {
  display: flex;
  width: 100%;
  overflow: hidden;
}

.table-lists {
  flex: 1;
  margin-top: 0;
  padding-top: 20px;
  border-left: 1px solid rgba(217, 217, 217, 1);
}

// 余额表
.tables {
  position: absolute;
  top: 40%;
  left: 50%;
  margin-left: -550px;
  margin-top: -300px;
  width: 1158px;
  height: 700px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 2000;
  padding-bottom: 30px;
  overflow-y: auto;
}

.content {
  width: 1136px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 auto;
}

.title {
  font-size: 20px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  text-align: center;
  margin: 24px 0;
}

.closes {
  position: absolute;
  right: 25px;
  top: 17px;
  font-size: 32px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}

.fix-flexsL {
  width: 25%;
  padding-right: 32px;
}

.fix-flexsR {
  width: 75%;
}

.fix-flexsLr {
  width: 100%;
  // max-width: auto;
}

.flex-max-widh {
  max-width: 304px;
}

.totip {
  position: absolute;
  left: 390px;
  top: 8px;
}

.noPadding {
  padding-right: 0;
}

.progressUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2022;
}

.progressBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 638px;
  height: 406px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.processText {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #2F54EB;
  line-height: 28px;
  margin-top: 25px;
}

.processTexted {
  color: #52C41A;
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  line-height: 28px;
  margin-top: 25px;
}

>>> .el-progress--circle .el-progress__text, .el-progress--dashboard .el-progress__text {
  top: 80%;
}

.undraw_upload {
  position: absolute;
  width: 180px;
  height: 132px;
  top: 90px;
}

.processTextedNo {
  visibility: hidden;
}

.baseVersion {
  height: 36px;
  line-height: 36px;
  padding: 0 6px;
  background: #2F54EB;
  margin: 4px 6px 0 0;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
</style>
